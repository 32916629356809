import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LatchDialogService } from '@latch/latch-web';
import * as moment from 'moment-timezone';
import { merge, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AppointmentSlot, BookableResource, BookingAppointment, Building, PaymentType, SlotTimeOfDay } from 'src/app/model/bookings';
import { UserConsentAction, UserConsentKey, UserConsentStatus } from 'src/app/model/user';
import { BookingService } from 'src/app/services/booking.service';
import { NativeAppService } from 'src/app/services/native-app.service';
import { UserService } from 'src/app/services/user.service';

enum Step {
  SelectResource,
  SelectTime,
  Summary,
  Terms,
  Payment,
}

export enum BookableStatus {
  Available = 'Available',
  Full = 'Full',
  Unavailable = 'Unavailable'
}

function createBookingValidator(): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    const resource = form.get('resource')?.value as BookableResource | undefined;
    const termsAndConditionsAccepted = form.get('termsAndConditionsAccepted')?.value as boolean;

    if (resource?.termsAndConditions && !termsAndConditionsAccepted) {
      return {
        termsAndConditions: 'Terms and Conditions have to be accepted.'
      };
    } else {
      return null;
    }
  };
}

@Component({
  selector: 'latch-bookings-create',
  templateUrl: './bookings-create.component.html',
  styleUrls: ['./bookings-create.component.scss']
})
export class BookingsCreateComponent implements OnInit, OnDestroy {
  isLoading = false;
  searchControl = new FormControl<string | undefined>(undefined, { nonNullable: true });
  resources?: BookableResource[];
  Step = Step;
  step = Step.SelectResource;
  days: Date[] = this.generateDays(moment().toDate());

  bookingForm = new FormGroup({
    resource: new FormControl<BookableResource | undefined>(undefined, { validators: Validators.required, nonNullable: true }),
    availableNow: new FormControl(false, { nonNullable: true }),
    date: new FormControl(moment().toDate(), { validators: Validators.required, nonNullable: true }),
    slots: new FormControl<AppointmentSlot[]>([], { nonNullable: true }),
    termsAndConditionsAccepted: new FormControl(false, { nonNullable: true }),
  }, createBookingValidator());

  SlotTimeOfDay = SlotTimeOfDay;
  slots?: AppointmentSlot[];
  maxSlots = 0;

  bookingAppointment?: BookingAppointment;

  PaymentType = PaymentType;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private bookingService: BookingService,
    private router: Router,
    private dialog: LatchDialogService,
    private nativeApp: NativeAppService,
    private userService: UserService
  ) { }

  get buildings(): Building[] {
    const buildings = this.resources?.map(resource => resource.building).reduce((prev, curr) => {
      if (curr) {
        const exists = prev.find(building => building.id === curr?.id);
        if (!exists) {
          prev.push(curr);
        }
      }
      return prev;
    }, [] as Building[]) as Building[];
    return buildings;
  }

  get termsAndConditionsAccepted(): boolean {
    return this.bookingForm.controls.termsAndConditionsAccepted.value;
  }

  get resource(): BookableResource | undefined {
    return this.bookingForm.controls.resource.value;
  }

  set resource(resource: BookableResource | undefined) {
    this.bookingForm.controls.resource.setValue(resource);
    moment.tz.setDefault(resource?.building.timezone);
    this.bookingForm.controls.date.setValue(moment().toDate());
    this.days = this.generateDays(moment().toDate());
    this.updateSlots();
  }

  ngOnInit(): void {
    this.nativeApp.back$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(() => this.back());

    merge(
      this.searchControl.valueChanges.pipe(debounceTime(250)),
      this.bookingForm.controls.availableNow.valueChanges,
    ).subscribe(
      () => this.handleLoad(this.searchControl.value, this.bookingForm.controls.availableNow.value)
    );
    this.handleLoad(this.searchControl.value, this.bookingForm.controls.availableNow.value);
  }

  ngOnDestroy(): void {
    moment.tz.setDefault();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  back() {
    switch (this.step) {
      case Step.SelectResource:
        this.router.navigate(['..']);
        break;
      case Step.SelectTime:
        this.setStep(Step.SelectResource);
        break;
      case Step.Summary:
        this.setStep(Step.SelectTime);
        break;
      case Step.Terms:
        if (this.bookingAppointment?.uuid) {
          this.router.navigate([`/bookings/${this.bookingAppointment?.uuid}`]);
        } else {
          this.setStep(Step.Summary);
        }
        break;
    }
  }

  setStep(step: Step) {
    if (this.step === Step.SelectTime && step === Step.Summary && this.resource?.additionalInformation) {
      this.dialog.openConfirmation({
        data: {
          title: 'Additional Information',
          messages: [this.resource.additionalInformation],
          confirmText: 'Proceed',
          confirmButtonClasses: ['latch-button-link'],
          secondaryButtonText: 'Cancel',
          secondaryButtonClasses: ['latch-button-link', 'latch-danger'],
          showCloseButton: false,
        }
      }).afterClosed().subscribe(
        shouldContinue => {
          if (shouldContinue) {
            this.step = step;
          }
        }
      );
    } else {
      if (step === Step.SelectTime) {
        this.updateSlots();
      }
      if (step === Step.Payment) {
        if (this.bookingAppointment?.checkoutUrl) {
          this.bookingService.payBookingAppointment(this.bookingAppointment);
          // navigates the web view to the main page right after opening url to avoid inconsistent state navigating away from the url
          if (this.nativeApp.isRunningInApp()) {
            this.router.navigate(['..']);
          }
        } else {
          this.router.navigate(['..']);
        }
        return;
      }
      this.step = step;
    }
  }

  handleLoad(searchTerm?: string, availableNow?: boolean): void {
    this.isLoading = true;
    this.bookingForm.controls.resource.setValue(undefined);
    this.bookingService.getResources(searchTerm, availableNow).subscribe({
      next: resources => {
        this.resources = resources;
        this.isLoading = false;
      },
      error: error => {
        this.isLoading = false;
        this.dialog.openConfirmation({
          data: {
            title: 'Error',
            messages: [error?.message ?? error],
            confirmText: 'OK',
            confirmButtonClasses: ['latch-button-link'],
            showCloseButton: false,
          }
        }).afterClosed().subscribe(
          () => {
            this.router.navigate(['..']);
          }
        );
      }
    });
  }

  createBookings() {
    if (this.resource) {
      this.isLoading = true;
      const slots = this.bookingForm.controls.slots.value;
      this.bookingService.createBookingAppointments(
        {
          bookableResource: this.resource,
          timeSlots: slots.map(slot => ({
            startTimeEpoch: slot.start,
            endTimeEpoch: slot.end,
          }))
        }
      ).pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe({
        next: bookingAppointments => {
          this.isLoading = false;
          if (this.resource?.bookingAmount?.paymentType && this.resource?.bookingAmount?.paymentType !== PaymentType.None) {
            // currently paid bookings only support creating one booking appointment
            this.bookingAppointment = bookingAppointments[0];
            this.isLoading = true;
            this.userService.getUserConsent(UserConsentKey.STRIPE_TOS).pipe(
              takeUntil(this.unsubscribe$),
            ).subscribe({
              next: userConsentResponse => {
                this.isLoading = false;
                if (userConsentResponse.status === UserConsentStatus.ACCEPTED) {
                  this.setStep(Step.Payment);
                } else {
                  this.setStep(Step.Terms);
                }
              },
              error: error => {
                this.isLoading = false;
                this.showErrorDialog(error);
              }
            });
          } else {
            this.dialog.openConfirmation({
              data: {
                title: 'Booking confirmed',
                messages: [`Your booking ${this.resource?.name ? 'for ' + this.resource?.name : ''} is now confirmed.`],
                confirmText: 'OK',
                confirmButtonClasses: ['latch-button-link'],
                showCloseButton: false,
              }
            }).afterClosed().subscribe(
              () => {
                this.router.navigate(['..']);
              }
            );
          }
        },
        error: error => {
          this.isLoading = false;
          this.showErrorDialog(error);
        }
      });
    }
  }

  showErrorDialog(error: any) {
    this.dialog.openConfirmation({
      data: {
        title: 'Error',
        messages: [error?.message ?? error],
        confirmText: 'OK',
        confirmButtonClasses: ['latch-button-link'],
        showCloseButton: false,
      }
    }).afterClosed().subscribe(
      () => {
        this.step = Step.SelectTime;
        this.bookingForm.controls.slots.setValue([]);
        this.updateSlots();
      }
    );
  }

  updateConsent() {
    this.isLoading = true;
    this.userService.updateUserConsent(UserConsentKey.STRIPE_TOS, UserConsentAction.ACCEPT).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: () => {
        this.isLoading = false;
        this.setStep(Step.Payment);
      },
      error: error => {
        this.isLoading = false;
        this.dialog.openConfirmation({
          data: {
            title: 'Error',
            messages: [error?.message ?? error],
            confirmText: 'OK',
            confirmButtonClasses: ['latch-button-link'],
            showCloseButton: false,
          }
        });
      }
    });
  }

  next() {
    if (this.resource) {
      const newDay = moment(this.bookingForm.controls.date.value).add(7, 'day');
      const maxDay = moment().add(this.resource.policy.maxAdvanceDays, 'days');
      this.bookingForm.controls.date.setValue(
        newDay.isAfter(maxDay, 'day') ? maxDay.toDate() : newDay.toDate()
      );
      if (moment(this.bookingForm.controls.date.value).isAfter(moment(this.days[6]), 'day')) {
        this.days = this.generateDays(moment(this.days[0]).add(7, 'day').toDate());
      }
      this.bookingForm.controls.slots.setValue([]);
      this.updateSlots();
    }
  }

  previous() {
    const newDay = moment(this.bookingForm.controls.date.value).subtract(7, 'day');
    const minDay = moment();
    this.bookingForm.controls.date.setValue(
      newDay.isBefore(minDay, 'day') ? minDay.toDate() : newDay.toDate()
    );
    if (moment(this.bookingForm.controls.date.value).isBefore(moment(this.days[0]), 'day')) {
      this.days = this.generateDays(moment(this.days[0]).subtract(7, 'day').toDate());
    }
    this.bookingForm.controls.slots.setValue([]);
    this.updateSlots();
  }

  generateDays(currentDay: Date): Date[] {
    const day = moment(currentDay).startOf('isoWeek');
    return [
      moment(day).toDate(),
      moment(day).add(1, 'day').toDate(),
      moment(day).add(2, 'day').toDate(),
      moment(day).add(3, 'day').toDate(),
      moment(day).add(4, 'day').toDate(),
      moment(day).add(5, 'day').toDate(),
      moment(day).add(6, 'day').toDate(),
    ];
  }

  abbreviateDay(day: Date): string {
    switch (moment(day).isoWeekday()) {
      case 1:
        return 'M';
      case 2:
        return 'T';
      case 3:
        return 'W';
      case 4:
        return 'R';
      case 5:
        return 'F';
      case 6:
      case 7:
        return 'S';
      default:
        return '';
    }
  }

  updateSlots(): void {
    if (this.resource) {
      this.isLoading = true;
      this.bookingService.getResourceTimeSlots(this.resource.uuid, moment(this.bookingForm.controls.date.value).unix()).pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe({
        next: slotsInfo => {
          if (this.resource) {
            this.bookingForm.controls.slots.setValue([]);
            this.maxSlots = this.resource.policy.maxSlotsPerPersonPerDay - slotsInfo.slotsBooked;
            this.slots = slotsInfo.slotAvailability
              .filter(s => moment().isBefore(s.end))
              .map(s => ({
                ...s,
                slotTimeOfDay: moment(s.start).hour() < 12 ?
                  SlotTimeOfDay.Morning :
                  moment(s.start).hour() < 18 ? SlotTimeOfDay.Afternoon : SlotTimeOfDay.Evening,
              }));
          }
          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.dialog.openConfirmation({
            data: {
              title: 'Error',
              messages: [error?.message ?? error],
              confirmText: 'OK',
              confirmButtonClasses: ['latch-button-link'],
              showCloseButton: false,
            }
          });
        }
      });
    }
  }

  isDaySelected(day: Date) {
    return moment(day).isSame(this.bookingForm.controls.date.value, 'day');
  }

  isDayDisabled(day: Date) {
    return moment(day).isBefore(moment(), 'day') ||
      moment(day).isAfter(moment().add(this.resource?.policy.maxAdvanceDays, 'days'), 'day');
  }

  isPreviousDisabled() {
    const dayBefore = moment(this.bookingForm.controls.date.value).subtract(1, 'day');
    return dayBefore.isBefore(moment(), 'day');
  }

  isNextDisabled() {
    const dayAfter = moment(this.bookingForm.controls.date.value).add(1, 'day');
    return dayAfter.isAfter(moment().add(this.resource?.policy.maxAdvanceDays, 'days'), 'day');
  }

  selectDate(day: Date) {
    if (!moment(this.bookingForm.controls.date.value).isSame(day)) {
      this.bookingForm.controls.date.setValue(day);
      this.bookingForm.controls.slots.setValue([]);
      this.updateSlots();
    }
  }

  buildingResources(building: Building): BookableResource[] {
    return this.resources?.filter(resource => resource.building?.id === building.id) ?? [];
  }

  toggleSlot(slot: AppointmentSlot) {
    const selectedSlots = this.bookingForm.controls.slots.value;
    if (this.isSlotSelected(slot)) {
      selectedSlots.splice(selectedSlots.indexOf(slot), 1);
      this.bookingForm.controls.slots.setValue(selectedSlots);
    } else {
      if (selectedSlots.length < this.maxSlots && slot.appointmentsAvailable > 0) {
        this.bookingForm.controls.slots.setValue([...selectedSlots, slot]);
      }
    }
  }

  isSlotSelected(slot: AppointmentSlot) {
    const selectedSlots = this.bookingForm.controls.slots.value;
    return selectedSlots.includes(slot);
  }

  /**
   * Check if slots exist for a specific slot slotTimeOfDay
   * @param slots list of slots to search
   * @param slotTimeOfDay Part of the day to search for
   */
  slotsExist(slots?: AppointmentSlot[], slotTimeOfDay?: SlotTimeOfDay): boolean {
    return slots?.some(s => s.slotTimeOfDay === slotTimeOfDay) ?? false;
  }

  getSlotDate(timeEpoch: number): string {
    return moment.unix(timeEpoch / 1000).format('h:mmA').toLowerCase();
  }

  getPrice(): number {
    return this.bookingForm.controls.slots.value.length * (this.resource?.bookingAmount?.timeSlotAmount ?? 0);
  }

  openTermsAndConditions() {
    if (this.resource?.termsAndConditions?.id) {
      this.isLoading = true;
      this.bookingService.getTermsAndConditionsDownloadUrl(this.resource.termsAndConditions.id).pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe({
        next: url => {
          this.nativeApp.openUrl(url);
          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.dialog.openConfirmation({
            data: {
              title: 'Error',
              messages: [error?.message ?? error],
              confirmText: 'OK',
              confirmButtonClasses: ['latch-button-link'],
              showCloseButton: false,
            }
          });
        }
      });
    }
  }
}
